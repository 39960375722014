import * as React from "react"
import loadable from '@loadable/component'

import Layout from "../components/layout/layout"
import CapabilitiesSection from "../components/sections/capabilities-section"
import Seo from "../components/seo"
import CtaSection from "../components/sections/cta-section"

const HubspotChecklist = loadable(() => import('../components/common/hubspot-checklist'))

const ChecklistPage = () => (
  <Layout>
    <Seo
      title='Download Disaster Recovery Checklist'
      description='Minimize your losses and stay one step ahead of potential disasters. You can request our disaster recovery checklist.'
    />
    <div className="bg-blue-100 py-12">
      <div className="w-11/12 max-w-xl mx-auto">
        <HubspotChecklist />
      </div>
    </div>
    <CtaSection />
    <CapabilitiesSection />
  </Layout>
)

export default ChecklistPage
